const dataAccordion = [
  {
    id: "One",
    headerId: "headingOne",
    question: "Вопрос 1",
    answer:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Repellendus voluptatem optio vero qui tenetur fugit, iusto pariatur consequuntur fugiat, tempora sequi repellat dolores labore neque sit quo expedita eaque possimus!",
    isOpened: true,
  },
  {
    id: "Two",
    headerId: "headingTwo",
    question: "Вопрос 2",
    answer:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Repellendus voluptatem optio vero qui tenetur fugit, iusto pariatur consequuntur fugiat, tempora sequi repellat dolores labore neque sit quo expedita eaque possimus!",
    isOpened: false,
  },
  {
    id: "Three",
    headerId: "headingThree",
    question: "Вопрос 3",
    answer:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Repellendus voluptatem optio vero qui tenetur fugit, iusto pariatur consequuntur fugiat, tempora sequi repellat dolores labore neque sit quo expedita eaque possimus!",
    isOpened: false,
  },
];

export default dataAccordion;
